<template>
	<div class="w-100">
		<h5 class="mb-0">{{ $t('invitePlayers') }}</h5>
		<hr />
		<b-form @submit.prevent="findPlayers()" class="row align-items-end">
			<div class="form-group col-6 col-md-4 mb-3">
				<label for="nick">{{ $t('enterPlayerNick') }}</label>
				<b-form-input v-model="player.nick" id="nick" name="nick" />
			</div>
			<div class="form-group col-6 col-md-4 mb-3">
				<label for="position">{{ $t('position') }}</label>
				<select
					name="position"
					id="position"
					class="form-control"
					v-model="player.position"
				>
					<option value=""></option>
					<option
						v-for="pos in positions"
						:key="pos.value"
						:value="pos.value"
						>{{ pos.text }}</option
					>
				</select>
			</div>
			<div class="col-12 col-md-4 mb-3">
				<b-button type="submit" block variant="primary">{{
					$t('search')
				}}</b-button>
			</div>
		</b-form>
		<hr />
		<b-table striped bordered hover :items="users" :fields="columns">
			<div slot="order" slot-scope="row" class="text-right">
				{{ row.index + 1 }}
			</div>
			<div slot="action" slot-scope="row" class="text-center">
				<b-button
					v-if="!row.item.invited"
					variant="primary"
					@click="sendInvitation(row.item.id)"
				>
					<i class="fa fa-user-plus"></i>
				</b-button>
			</div>
		</b-table>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				users: [],
				player: {},
				userSelected: null,
				teamId: this.$route.params.teamId,
				positions: window.positions,
				columns: [
					{ key: 'order', label: '' },
					{ key: 'nick', label: this.$t('player'), sortable: true },
					{ key: 'country', label: this.$t('country'), sortable: true },
					{ key: 'position', label: this.$t('position'), sortable: true },
					{ key: 'action', label: this.$t('show'), sortable: false },
				],
			}
		},
		computed: {
			...mapGetters(['loading', 'lang', 'console', 'team']),
		},
		mounted() {},
		methods: {
			sendInvitation(userId) {
				const payload = {
					user_id: userId,
					tournament_id: this.$route.query.tournament,
				}
				const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/send-invitation-player`
				this.$axios.post(path, payload).then((response) => {
					this.users = this.users.map((u) => {
						if (u.id === userId) {
							u.invited = true
						}
						return u
					})
					const data = response.data
					let message = this.$t('updatedInfo')
					if (data.message) {
						message = data.message
					}
					this.$toastr.success(message, window.TITLE_SUCCESS)
				})
			},
			findPlayers() {
				const params = {
					nick: this.player.nick ? this.player.nick : '',
					position: this.player.position ? this.player.position : '',
				}
				const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/find-users`
				this.$axios.get(path, { params }).then((response) => {
					const positions = window.positions
					this.users = response.data.data.map((p) => {
						p.invited = false
						const position = p.position
						p.position = positions
							.filter((pos) => {
								return pos.value === position
							})
							.reduce((pos) => {
								return pos
							}).text
						return p
					})
				})
			},
		},
	}
</script>
